import type { ZeroPriceDisplayValue } from 'root/components/Menu/panels/Settings/types';
import { ZERO_PRICE_DISPLAY_OPTIONS, SHOW_PLACEHOLDER_IMAGE_OPTIONS } from 'root/components/Menu/consts';

export const getShouldDisplayZeroPrice = (
  zeroPriceDisplayOption: ZeroPriceDisplayValue,
  sectionId: string | undefined,
  zeroPriceDisplaySpecificSectionIds: string[] | undefined
) => {
  const isShowOnSpecific = zeroPriceDisplayOption === ZERO_PRICE_DISPLAY_OPTIONS.SHOW_ON_SPECIFIC;
  const isFirstTimeSelectingSpecificDisplay = isShowOnSpecific && !zeroPriceDisplaySpecificSectionIds;
  if (isFirstTimeSelectingSpecificDisplay) {
    return true;
  }
  return (
    zeroPriceDisplayOption === ZERO_PRICE_DISPLAY_OPTIONS.SHOW_ON_ALL ||
    (isShowOnSpecific && !!sectionId && (zeroPriceDisplaySpecificSectionIds || []).includes(sectionId))
  );
};

export const getShouldDeleteZeroPrice = ({
  price,
  isExpandMenuSettingsExperimentEnabled,
  zeroPriceDisplayOption,
  sectionId,
  zeroPriceDisplaySpecificSectionIds,
}: {
  price: string | undefined;
  isExpandMenuSettingsExperimentEnabled: boolean;
  zeroPriceDisplayOption: ZeroPriceDisplayValue;
  sectionId: string | undefined;
  zeroPriceDisplaySpecificSectionIds: string[] | undefined;
}) => {
  const itemHasNoPrice = Number(price || '0.00') === 0;
  const shouldDeleteZeroPrice =
    isExpandMenuSettingsExperimentEnabled &&
    itemHasNoPrice &&
    !getShouldDisplayZeroPrice(zeroPriceDisplayOption, sectionId, zeroPriceDisplaySpecificSectionIds);
  return shouldDeleteZeroPrice;
};

export const getShouldDisplayPlaceholderImage = (
  placeholderImageDisplayValue: string,
  sectionId: string,
  sectionsWithPlaceholderImageIds?: string[]
) => {
  const isShowOnSpecific = placeholderImageDisplayValue === SHOW_PLACEHOLDER_IMAGE_OPTIONS.SPECIFIC;
  const isFirstTimeSelectingSpecificDisplay = isShowOnSpecific && !sectionsWithPlaceholderImageIds;
  if (isFirstTimeSelectingSpecificDisplay) {
    return true;
  }
  return (
    placeholderImageDisplayValue === SHOW_PLACEHOLDER_IMAGE_OPTIONS.ALL ||
    (isShowOnSpecific && (sectionsWithPlaceholderImageIds || []).includes(sectionId))
  );
};
