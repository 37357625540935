export const LABELS_WIDGET_COMPONENT_IDS = {
  labelsLayouter: '#labelsLayouter',
  labelsRepeater: '#labelsRepeater',
  labelWrapper: '#box1',
  labelContainer: '#labelContainer',
  labelText: '#labelText',
  labelIcon: '#labelIcon',
} as const;

export const LABELS_LIMIT = 5;
export const UNSELECTED_LABELS_COMPONENT_IDS = Object.values(LABELS_WIDGET_COMPONENT_IDS);
