import { listItems, getItem } from '@wix/ambassador-restaurants-menus-v1-item/http';
import type { Item } from 'root/types';
import type { HttpClientFactory } from './utils/types';

export const ItemsClient: HttpClientFactory<Item> = (httpClient) => ({
  get: async ({ id }) => {
    return httpClient
      .request(getItem({ itemId: id }))
      .then((res) => {
        const {
          data: { item = {} },
        } = res;

        return { data: item as Item };
      })
      .catch((e) => {
        throw new Error(`Error when calling getItem with id ${id}- ${e}`);
      });
  },
  getAll: async ({ ids, paging }) => {
    return httpClient
      .request(listItems({ itemIds: ids, paging }))
      .then((res) => {
        const {
          data: { items = [], pagingMetadata },
        } = res;

        return { data: items as Item[], pagingMetadata };
      })
      .catch((e) => {
        throw new Error(`Error when calling getItems- ${e}`);
      });
  },
});
