import { chunkArray } from './chunkArray';
import { MAX_NUMBER_OF_ENTITIES_FROM_SERVER } from 'root/utils/consts';
import type { Menu, Section, Item, Label, MenusEntity } from 'root/types';
import type { GetAllResponse, IMenusHTTPClient, CursorPaging } from './types';

export const completeMissingEntities = async (
  menu: Menu,
  sections: GetAllResponse<Section>,
  items: GetAllResponse<Item>,
  labels: GetAllResponse<Label>,
  getSections: IMenusHTTPClient<Section>['getAll'],
  getItems: IMenusHTTPClient<Item>['getAll'],
  getLabels: IMenusHTTPClient<Label>['getAll'],
  maxNumberOfEntitiesToLoad?: CursorPaging['limit']
) => {
  await completeMissingEntity(menu.sectionIds || [], sections, getSections, maxNumberOfEntitiesToLoad);
  const itemIds = [...new Set(sections.data.flatMap((section) => section.itemIds || []))];
  await completeMissingEntity(itemIds || [], items, getItems, maxNumberOfEntitiesToLoad);
  // TODO: uncomment when labels pagination is supported https://jira.wixpress.com/browse/RST-6576
  // const labelIds = [
  //   ...new Set(
  //     items.reduce((acc, item) => [...acc, ...(item.labels?.map((label) => label.id as string) || [])], [] as string[])
  //   ),
  // ];
  // await completeMissingEntity(labelIds, labels, getLabels);
};

export const completeMissingEntity = async <T extends MenusEntity>(
  ids: string[],
  entities: GetAllResponse<T>,
  getEntities: IMenusHTTPClient<T>['getAll'],
  maxNumberOfEntitiesToLoad: CursorPaging['limit']
) => {
  if (entities.pagingMetadata?.hasNext) {
    const missingEntitiesIds = ids.reduce((acc: string[], id: string) => {
      const currEntity = entities.data.find((entity) => entity.id === id);
      return currEntity ? acc : [...acc, id];
    }, []);
    if (missingEntitiesIds.length > 0) {
      const missingEntitiesIdsChunks = chunkArray(
        missingEntitiesIds,
        maxNumberOfEntitiesToLoad || MAX_NUMBER_OF_ENTITIES_FROM_SERVER
      );
      const missingEntities = await Promise.all(
        missingEntitiesIdsChunks.map((missingIds: string[]) => getEntities({ ids: missingIds }))
      );
      entities.data.push(...missingEntities.flatMap((entity) => entity.data));
    }
  }
};
