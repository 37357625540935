import type { HorizontalMenuItem, Menu, PopulatedMenu, PopulatedSection } from 'root/types';
import type { SortableMenu, MenusOrder, ShowMenuValue, SetMenuSharedDeviceGfpp, SetMenuGfpp } from './types';
import type { ControllerFlowAPI, ILocation, ISiteApis, StructurePage } from '@wix/yoshi-flow-editor';
import { GENERAL_MENUS_ARTICLE_ID, HASH_TO_PREVENT_SCROLL, PAGE_DATA, PANEL_IDS } from 'root/utils/consts';
import type { TPAPageId } from '@wix/platform-editor-sdk';
import type { GfppDesktopBuilder } from '@wix/app-manifest-builder';
import { SHOW_MENUS_OPTIONS } from './consts';

export const getSortableMenusWithOrder = (
  unsortedMenus: Menu[],
  menusDisplayOption: ShowMenuValue,
  menusOrder?: MenusOrder
): SortableMenu[] => {
  const res: SortableMenu[] = [];
  const menusMap = unsortedMenus.reduce(
    (acc: Record<string, Menu>, menu) => ({ ...acc, [menu.id as string]: menu }),
    {}
  );
  menusOrder?.forEach((menuId) => {
    const menu = menusMap[menuId];
    if (menu) {
      res.push({ id: menu.id as string, name: menu.name as string, checked: true });
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete menusMap[menuId];
    }
  });

  const isShowAllMenus = menusDisplayOption === SHOW_MENUS_OPTIONS.ALL;
  const isMenusOrderNotUpdated = !!menusOrder && isShowAllMenus && menusOrder.length !== unsortedMenus.length;
  const isCheckedByDefault = !menusOrder || isMenusOrderNotUpdated;

  Object.values(menusMap).forEach((menu) => {
    res.push({
      id: menu.id as string,
      name: menu.name as string,
      checked: isCheckedByDefault,
    });
  });
  return res;
};

export const truncate = (str: string, maxChars: number) => {
  return str.length > maxChars ? `${str.slice(0, maxChars - 1)}...` : str;
};

export const getMenusSEOData = ({
  currentMenu,
  pageUrl,
  environment,
  menusOrder,
  firstMenu,
}: {
  currentMenu: PopulatedMenu;
  pageUrl: string;
  environment: ControllerFlowAPI['environment'];
  menusOrder: MenusOrder;
  firstMenu?: Menu;
}) => {
  const menuItems = currentMenu.sections.flatMap((section: PopulatedSection) => section.items);
  const { multilingual } = environment;
  const isMainMenu = menusOrder?.[0] === currentMenu.id || firstMenu?.id === currentMenu.id;

  return {
    menu: {
      ...currentMenu,
      url: pageUrl,
      isMainMenu,
    },
    items: menuItems,
    translatedPages: multilingual,
  };
};

export const getIsCurrentPageMenusDefaultPage = async (site: ISiteApis) => {
  const allPages = (await site.getSiteStructure({ includePageId: true })).pages as (StructurePage & {
    tpaPageId: TPAPageId;
  })[];
  const currentPageTpaId = allPages.find((page) => page.id === site.currentPage?.id)?.tpaPageId;
  return currentPageTpaId === PAGE_DATA.pageId;
};

const updateMenusOrderToIncludeAllMenus = (menus: Menu[], menusOrder: MenusOrder): MenusOrder => {
  const res: MenusOrder = [];
  const menusMap = menus.reduce((acc: Record<string, Menu>, menu) => ({ ...acc, [menu.id as string]: menu }), {});

  menusOrder.forEach((menuId) => {
    const menu = menusMap[menuId];
    if (menu) {
      res.push(menu.id as string);
      // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
      delete menusMap[menuId];
    }
  });

  Object.values(menusMap).forEach((menu) => {
    res.push(menu.id as string);
  });

  return res;
};

export const getFilteredMenusOrder = ({
  menus,
  menusOrder,
  menusDisplayOption,
}: {
  menus: Menu[];
  menusOrder?: MenusOrder;
  menusDisplayOption?: ShowMenuValue;
}): MenusOrder | undefined => {
  if (!menusOrder) {
    return undefined;
  }

  const shouldShowAllMenus = menusDisplayOption === SHOW_MENUS_OPTIONS.ALL;
  const shouldAddNewMenuToMenusOrder = shouldShowAllMenus && menusOrder.length < menus.length;

  if (shouldAddNewMenuToMenusOrder) {
    return updateMenusOrderToIncludeAllMenus(menus, menusOrder);
  }

  return menusOrder.filter((id) => menus.some((menu) => menu.id === id));
};

export const isValidMenuQueryParam = (queryParam?: string): queryParam is string => {
  return !!queryParam && queryParam !== 'undefined';
};

const addQuestionMarkIfNeeded = (searchParams: URLSearchParams) => (searchParams.toString() ? `?${searchParams}` : '');
const createCleanLink = (url: string) => {
  const link = new URL(url);
  link.searchParams.delete('menu');
  link.searchParams.delete('menuId');
  link.hash = HASH_TO_PREVENT_SCROLL;
  return link;
};

export const getNavigationBarItems = (
  navigationBarItems: string[],
  menusMap: Record<string, PopulatedMenu>,
  activeMenuId: string,
  shouldLinkBeFullUrl: boolean,
  location: ILocation
): HorizontalMenuItem[] => {
  const activeMenu = menusMap[activeMenuId];

  const menuItems: HorizontalMenuItem[] = navigationBarItems.map((menuId, idx) => {
    const menu = menusMap[menuId];
    const link = createCleanLink(location.url);

    if (idx > 0) {
      if (isValidMenuQueryParam(menu?.urlQueryParam)) {
        link.searchParams.set('menu', menu?.urlQueryParam);
      } else {
        link.searchParams.set('menuId', menuId);
      }
    }

    return {
      label: truncate(menu?.name || '', 20),
      link: shouldLinkBeFullUrl ? link.toString() : `#${addQuestionMarkIfNeeded(link.searchParams)}`,
      id: menuId,
      selected: isValidMenuQueryParam(activeMenu?.urlQueryParam)
        ? menu?.urlQueryParam === activeMenu?.urlQueryParam
        : (idx === 0 && !activeMenuId) || menuId === activeMenuId,
    };
  });

  return menuItems;
};

const setMenuWidgetCommonGfpp = ({
  gfppBuilder,
  isExpandMenuSettingsExperimentEnabled,
  isNewPresetPanelExperimentEnabled,
  isLayoutCustomizationExperimentEnabled,
  t,
}: SetMenuSharedDeviceGfpp) => {
  const changeLayoutAction = { label: t('app.gfpp.change-layout'), actionId: PANEL_IDS.changeMenuLayout };
  const layoutCustomizationAction = {
    label: t('app.gfpp.change-layoutg'),
    actionId: PANEL_IDS.menuLayoutCustomization,
  };
  const menuSettingsAction = {
    label: t('app.gfpp.menu-settings'),
    actionId: PANEL_IDS.menuSettings,
  };
  const shouldShowLayoutBtn =
    (isExpandMenuSettingsExperimentEnabled && !isNewPresetPanelExperimentEnabled) ||
    isLayoutCustomizationExperimentEnabled;
  const shouldShowMainAction2Btn = !isNewPresetPanelExperimentEnabled || isExpandMenuSettingsExperimentEnabled;
  gfppBuilder
    .set('help', { id: GENERAL_MENUS_ARTICLE_ID })
    .set('mainAction1', {
      label: t('app.gfpp.manage-menus'),
      actionId: PANEL_IDS.manageMenus,
    })
    .set('settings', { actionId: PANEL_IDS.settings });

  shouldShowLayoutBtn &&
    gfppBuilder.set('layout', isLayoutCustomizationExperimentEnabled ? layoutCustomizationAction : changeLayoutAction);
  shouldShowMainAction2Btn
    ? gfppBuilder.set('mainAction2', isExpandMenuSettingsExperimentEnabled ? menuSettingsAction : changeLayoutAction)
    : gfppBuilder.set('mainAction2', { behavior: 'HIDE' });
};

const setMenuWidgetDesktopGfpp = (gfppBuilder: GfppDesktopBuilder<'widget'>) => {
  gfppBuilder.set('connect', { behavior: 'HIDE' });
};

export const setMenuWidgetGfpp = ({
  desktopGfppBuilder,
  mobileGfppBuilder,
  isExpandMenuSettingsExperimentEnabled,
  isNewPresetPanelExperimentEnabled,
  isLayoutCustomizationExperimentEnabled,
  t,
}: SetMenuGfpp) => {
  [desktopGfppBuilder, mobileGfppBuilder].forEach((gfppBuilder) => {
    setMenuWidgetCommonGfpp({
      gfppBuilder,
      isExpandMenuSettingsExperimentEnabled,
      isLayoutCustomizationExperimentEnabled,
      isNewPresetPanelExperimentEnabled,
      t,
    });
  });

  setMenuWidgetDesktopGfpp(desktopGfppBuilder);
};
