export const MENUS_SHOWCASE_APP_DEF_ID = 'b278a256-2757-4f19-9313-c05c783bec92';
export const OLO_APP_DEF_ID = '9a5d83fd-8570-482e-81ab-cfa88942ee60';
export const MENUS_SHOWCASE_BM_URL = '/restaurants/menus-management';

export const MENUS_CONTROLLER_WIDGET_ID = 'b278a256-2757-4f19-9313-c05c783bec92-ds0ux';
export const COLUMNS_CONTROLLER_WIDGET_ID = 'b278a256-2757-4f19-9313-c05c783bec92-zqn1n';

export const PANEL_IDS = {
  changeMenuLayout: 'changeMenuLayout',
  changeColumnsLayout: 'changeColumnsLayout',
  manageMenus: 'manageMenus',
  columnsManageMenus: 'columnsManageMenus',
  menuElementsPanel: 'openShowHidePanel',
  menuLayoutCustomization: 'LayoutCustomization',
  columnsElementsPanel: 'openColumnsShowHidePanel',
  settings: 'settings',
  menuSettings: 'menuSettings',
  labelStyle: 'labelsStyle',
};

export const MENU_WIDGET_COMPONENT_IDS = {
  navigationBar: '#navigationBar',
  menuTitle: '#menuTitle',
  menuDescription: '#menuDescription',
  menuImage: '#menuImage',
  columns: '#columns',
  menuContainer: '#menuContainer',
  menuMultiStateBox: '#multiStateBox1' as const,
  errorStateTitle: '#menuErrorStateTitle',
  emptyStateTitle: '#menuEmptyStateTitle',
  sbsImage: '#imageX1',
} as const;

export const MENU_WIDGET_LAYOUT_PANELS_THUMBNAIL_IDS = {
  layoutPanelDesktop: '#panelThumbnailsDesktop',
  layoutPanelMobile: '#panelThumbnailsMobile',
};

export const COLUMNS_WIDGET_COMPONENT_IDS = {
  column1: '#column11',
  column2: '#column22',
  column3: '#column33',
};

export const SECTION_WIDGET_COMPONENT_IDS = {
  items: '#items',
  sectionsRepeater: '#sectionsRepeater',
  sectionTitleAndDescription: '#sectionTitleAndDescription',
  sectionWrapper: '#sectionWrapper',
  sectionTitle: '#sectionTitle',
  sectionDescription: '#sectionDescription',
  sectionDivider: '#sectionDivider',
} as const;

export const MENU_WIDGET_PRESET_IDS = {
  listOneColumn: 'List 1 column',
  listTwoColumn: 'List 2 column',
  listThreeColumn: 'List 3 column',
  listOneColumnWithImage: 'List 1 column w/image',
  sideBySideOneColumn: 'Side by Side 1 column',
  sideBySideTwoColumn: 'Side by Side 2 column',
  grid: 'Grid',
};

export const MENU_WIDGET_PRESET_IDS_MOBILE = {
  center: 'List 1 column',
  listOneColumnWithImage: 'List 1 column w/image - mobile',
  cards: 'Grid mobile',
  list2And3Column: 'List 2&3 column - mobile',
};

export const TWO_COLUMNS_PRESETS = [MENU_WIDGET_PRESET_IDS.listTwoColumn, MENU_WIDGET_PRESET_IDS.sideBySideTwoColumn];
export const THREE_COLUMNS_PRESETS = [MENU_WIDGET_PRESET_IDS.listThreeColumn];

export const DEFAULT_MENU_PRESET_ID = MENU_WIDGET_PRESET_IDS.listTwoColumn;

export const DEFAULT_MENU_PRESET_ID_MOBILE = 'Center';

export const MENU_WIDGET_PRESET_WITH_IMG_IDS = [
  MENU_WIDGET_PRESET_IDS.listOneColumnWithImage,
  MENU_WIDGET_PRESET_IDS.grid,
];

export const PRESET_SECTIONS_IDS_MAPPER = {
  listOneColumn: [COLUMNS_WIDGET_COMPONENT_IDS.column2],
  listTwoColumn: [COLUMNS_WIDGET_COMPONENT_IDS.column1, COLUMNS_WIDGET_COMPONENT_IDS.column2],
  listThreeColumn: [
    COLUMNS_WIDGET_COMPONENT_IDS.column1,
    COLUMNS_WIDGET_COMPONENT_IDS.column2,
    COLUMNS_WIDGET_COMPONENT_IDS.column3,
  ],
  listOneColumnWithImage: [COLUMNS_WIDGET_COMPONENT_IDS.column1],
  sideBySideOneColumn: [COLUMNS_WIDGET_COMPONENT_IDS.column1],
  sideBySideTwoColumn: [COLUMNS_WIDGET_COMPONENT_IDS.column1, COLUMNS_WIDGET_COMPONENT_IDS.column2],
};

export const UNSELECTED_SECTIONS_COMPONENT_IDS = [
  SECTION_WIDGET_COMPONENT_IDS.sectionsRepeater,
  SECTION_WIDGET_COMPONENT_IDS.sectionTitleAndDescription,
  SECTION_WIDGET_COMPONENT_IDS.sectionTitle,
  SECTION_WIDGET_COMPONENT_IDS.sectionDescription,
  SECTION_WIDGET_COMPONENT_IDS.sectionWrapper,
  SECTION_WIDGET_COMPONENT_IDS.items,
];

export const PARENT_APP_DEF_ID = '9142e27e-2e30-4948-ad8f-7e128939efc4';

export const LIST_1_COLUMN_PRESET_ID = 'variants-ldk3k0lx';
export const LIST_2_COLUMN_PRESET_ID = 'variants-ldlihuql';
export const LIST_3_COLUMN_PRESET_ID = 'variants-ldlm40ev';
export const LIST_2_AND_3_COLUMN_MOBILE_PRESET_ID = 'variants-lemh3y17';
export const LIST_1_COLUMN_WITH_IMAGE_PRESET_ID = 'variants-ldk5ggj8';
export const LIST_1_COLUMN_WITH_IMAGE_MOBILE_PRESET_ID = 'variants-lfatjaf6';
export const SIDE_BY_SIDE_1_COLUMN_PRESET_ID = 'variants-ldlj7gv3';
export const SIDE_BY_SIDE_2_COLUMN_PRESET_ID = 'variants-ldljrday';
export const GRID_PRESET_ID = 'variants-lezpw7bm';
export const CARD_MOBILE_PRESET_ID = 'variants-lfghbwzd';

const APP_PRESETS = {
  desktop: LIST_2_COLUMN_PRESET_ID,
  mobile: LIST_1_COLUMN_PRESET_ID,
};

export const PAGE_DATA = {
  pageId: 'menus-page',
  title: 'app.page.title',
  widgetId: 'a1fdf2bb-a0d4-453e-80be-3c796d083e36',
  desktopPresetId: APP_PRESETS.desktop,
  mobilePresetId: APP_PRESETS.mobile,
  pageUriSEO: 'menus',
};

export const MENU_COMPONENT_REF_ID = 'comp-lff722t8_r_comp-ldj6y4c1';

export const LEARN_MORE_ARTICLE_ID = '408c2918-4b3f-4d4e-a3e8-75b9a5ed25e9';
export const GENERAL_MENUS_ARTICLE_ID = 'e30ee869-45bc-4f68-99cd-ab1f7e6c1351';
export const SETTINGS_PANEL_ARTICLE_ID = '0ee67a30-0d07-4162-9ed6-59ecfe602e09';
export const DESIGN_PANEL_ARTICLE_ID = 'dbf2fcb5-c5a0-4b87-ac43-8a3e292a031b';
export const MENUS_ELEMENTS_PANEL_ARTICLE_ID = 'cedf304b-a5e1-4d87-9d5c-bbe2604f5099';
export const COLUMNS_ELEMENTS_PANEL_ARTICLE_ID = 'ff459af4-30b5-4580-b931-81c41c4ab862';
export const MENU_PRESETS_PANEL_ARTICLE_ID = '8f77767a-6dc3-49b1-9324-eb43e1a63eb9';
export const ELEMENT_PROPERTY = {
  TEXT: 'text',
  ALT: 'alt',
  SRC: 'src',
};

// Trial and error - 100 is too much
export const MAX_NUMBER_OF_ENTITIES_FROM_SERVER = 75;

export const APP_PAGE_ICON_ID = 'restaurantsPageType';

export const MENUS_COMP_REF_SUFFIX = 'comp-ldj6y4c1';

export const HASH_TO_PREVENT_SCROLL = '#1';
