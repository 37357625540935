import {
  restaurantsEditorPanelLoad,
  menusEditorElementsPanelCheckboxAction,
  menusEditorMenuLayoutPick,
  menusLiveSiteEditorPreviewNavigationClick,
} from '@wix/bi-logger-restaurants/v2';
import type { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { MENU_WIDGET_NAME } from 'root/components/Menu/consts';
import { SECTION_WIDGET_NAME } from 'root/components/Section2/consts';
import MenuWidget from 'root/components/Menu/.component.json';
import SectionWidget from 'root/components/Section2/.component.json';
import { getSitePageName, MENU_ELEMENTS_PANEL, SECTION_ELEMENTS_PANEL } from './bi';
import { MENU_WIDGET_PRESET_WITH_IMG_IDS } from './consts';

export class BiReporter {
  private sitePageName?: string = '';
  private siteMetasiteId?: string = '';

  constructor(private biLogger: EditorScriptFlowAPI['bi'], private widgetInstanceId: string) {}

  async init(editorSDK: FlowEditorSDK) {
    this.sitePageName = await getSitePageName(editorSDK.pages);
    this.siteMetasiteId = await editorSDK.info.getMetaSiteId('');
  }

  reportMenuWidgetPanelBi(origin: string, panelName: string) {
    this.biLogger?.report(
      restaurantsEditorPanelLoad({
        origin,
        panelName,
        product: 'menus',
        sitePageName: this.sitePageName,
        widgetId: MenuWidget.id,
        widgetInstanceId: this.widgetInstanceId,
        widgetName: MENU_WIDGET_NAME,
        msid: this.siteMetasiteId,
      })
    );
  }

  reportSectionWidgetPanelBi(origin: string, panelName: string) {
    this.biLogger?.report(
      restaurantsEditorPanelLoad({
        origin,
        panelName,
        product: 'menus',
        sitePageName: this.sitePageName,
        widgetId: SectionWidget.id,
        widgetInstanceId: this.widgetInstanceId,
        widgetName: SECTION_WIDGET_NAME,
        msid: this.siteMetasiteId,
      })
    );
  }

  reportElementCheckboxAction(panel: 'menu' | 'section') {
    this.biLogger?.report(
      menusEditorElementsPanelCheckboxAction({
        sitePageName: this.sitePageName,
        widgetInstanceId: this.widgetInstanceId,
        widgetId: panel === 'menu' ? MenuWidget.id : SectionWidget.id,
        widgetName: panel === 'menu' ? MENU_WIDGET_NAME : SECTION_WIDGET_NAME,
        panelName: panel === 'menu' ? MENU_ELEMENTS_PANEL : SECTION_ELEMENTS_PANEL,
        msid: this.siteMetasiteId,
      })
    );
  }

  reportMenuLayoutPickBi(chosenPreset: string) {
    this.biLogger?.report(
      menusEditorMenuLayoutPick({
        allowImages: MENU_WIDGET_PRESET_WITH_IMG_IDS.includes(chosenPreset),
        layout: chosenPreset,
        widgetId: MenuWidget.id,
        widgetInstanceId: this.widgetInstanceId,
        widgetName: MENU_WIDGET_NAME,
        msid: this.siteMetasiteId,
      })
    );
  }

  reportMenusHorizontalNavigationClickBi(numMenus: number, pageName: string | undefined) {
    this.biLogger?.report(
      menusLiveSiteEditorPreviewNavigationClick({
        numMenus,
        pageName,
        widgetId: MenuWidget.id,
        widgetInstanceId: this.widgetInstanceId,
        widgetName: MENU_WIDGET_NAME,
      })
    );
  }
}
