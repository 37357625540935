import type { ControllerParams, Label } from 'root/types';
import type { I$W } from '@wix/yoshi-flow-editor';
import { LABELS_WIDGET_COMPONENT_IDS } from './consts';
import type { LabelsWidgetProps } from './types';
import type labelsModel from './model';

type Bind = ControllerParams<typeof labelsModel>['$bind'];
type Props = ControllerParams<typeof labelsModel>['$props'];

export class LabelsController {
  constructor(
    private $w: I$W,
    private $bind: Bind,
    private $props: Props,
    private isUseBindExperimentEnabled: boolean
  ) {
    if (!isUseBindExperimentEnabled) {
      this.$w(LABELS_WIDGET_COMPONENT_IDS.labelsRepeater)?.onItemReady?.(
        async ($item: I$W, itemData: Label & { _id: string }) => {
          $item(LABELS_WIDGET_COMPONENT_IDS.labelText).text = itemData.name;
          $item(LABELS_WIDGET_COMPONENT_IDS.labelIcon).src = itemData.icon?.url;
        }
      );
    }
  }

  init(data: LabelsWidgetProps) {
    if (this.isUseBindExperimentEnabled) {
      this.$bind(LABELS_WIDGET_COMPONENT_IDS.labelsRepeater, {
        data: () =>
          (this.$props.data?.labels || []).map(({ id, ...rest }: Label) => ({
            _id: id,
            ...rest,
          })),
        item(label: Label, $bindItem) {
          $bindItem(LABELS_WIDGET_COMPONENT_IDS.labelText, {
            text: () => label.name || '',
          });
          $bindItem(LABELS_WIDGET_COMPONENT_IDS.labelIcon, {
            src: () => label.icon?.url || '',
          });
        },
      });
    } else {
      const { labels } = data;
      this.$w(LABELS_WIDGET_COMPONENT_IDS.labelsRepeater).data = labels.map(({ id, ...rest }) => ({
        _id: id,
        ...rest,
      }));
    }
  }
}
