import { ModifierType } from 'root/types';
import type {
  PopulatedMenu,
  PopulatedSection,
  ItemWithLabelsAndVariants,
  Menu,
  Section,
  Item,
  Label,
  Modifier,
  ModifierWithPrice,
} from 'root/types';

export const populateMenu = (
  menu: Menu,
  sections: Section[],
  items: Item[],
  labels: Label[],
  modifiers: Modifier[],
  shouldFormatModifiersPrice?: boolean
): PopulatedMenu => {
  const menuSections = menu.sectionIds
    ?.map((sectionId) => sections.find((section) => section.id === sectionId))
    ?.filter((section) => !!section) as PopulatedSection[];

  const sectionsWithItems = menuSections?.map((section) => {
    const sectionItems = section?.itemIds
      ?.map((itemId) => items.find((item) => item.id === itemId))
      ?.filter((item) => !!item) as Item[];

    const itemsWithLabelsAndVariants = sectionItems?.map((item) => ({
      ...item,
      labels: item?.labels
        ?.map((itemLabel) => labels.find((label) => label.id === itemLabel.id))
        ?.filter((label) => !!label),
      priceVariants: item?.priceVariants?.priceVariants
        ?.map((priceVariant) => {
          const priceVariantAsModifier = modifiers.find(
            (modifier) => modifier.id === priceVariant.modifierId && modifier.type === ModifierType.VARIANT
          );
          return {
            ...priceVariantAsModifier,
            price: shouldFormatModifiersPrice ? priceVariant.price?.replace(',', '') : priceVariant.price,
          } as ModifierWithPrice;
        })
        .filter((modifier) => !!modifier),
    })) as ItemWithLabelsAndVariants[];

    return {
      ...section,
      items: itemsWithLabelsAndVariants,
    };
  }) as PopulatedSection[];

  return {
    ...menu,
    sections: sectionsWithItems,
  };
};
