import type { FlowEditorSDK, PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';

export const MENU_SETTINGS_PANEL = 'menu settings';
export const MENU_ELEMENTS_PANEL = 'menu elements';
export const MENU_LAYOUT_PANEL = 'menu layout';
export const MENU_LAYOUT_CUSTOMIZATION_PANEL = 'menu layout customization';
export const MENU_PRESETS_PANEL = 'menu presets';
export const SECTION_ELEMENTS_PANEL = 'section elements';
export const LABEL_STYLE_PANEL = 'label style';

export const MENU_ELEMENTS_ORIGIN = 'menu elements button';
export const SECTION_ELEMENTS_ORIGIN = 'section elements button';
export const MENU_SETTINGS_ORIGIN = 'menu settings button';
export const LABEL_STYLE_ORIGIN = 'label styles button';
export const MENU_LAYOUT_ORIGIN = 'menu layout button';
export const MENU_LAYOUT_CUSTOMIZATION_ORIGIN = 'menu layout customization button';

export const getHostingEnvironment = (environment: PlatformControllerFlowAPI['environment']) => {
  if (environment.isEditor) {
    return 'editor';
  } else if (environment.isEditorX) {
    return 'editor x';
  } else if (environment.isADI) {
    return 'adi';
  } else if (environment.isPreview) {
    return 'preview';
  } else if (environment.isMobile) {
    return 'mobile';
  } else if (environment.isViewer) {
    return 'viewer';
  } else {
    return undefined;
  }
};

export const getSitePageName = async (pages?: FlowEditorSDK['pages']) => {
  const pageRef = await pages?.getCurrent('token');
  const sitePageName = pageRef && (await pages?.getPageData('token', { pageRef }))?.title;

  return sitePageName;
};
