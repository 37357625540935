import type { PopulatedMenu } from 'root/types';
import type { HttpClientFactory } from './utils/types';

import { MenusClient } from './MenusClient';
import { SectionsClient } from './SectionsClient';
import { ItemsClient } from './ItemsClient';
import { LabelsClient } from './LabelsClient';
import { populateMenu } from 'root/api/utils/populateMenu';
import { completeMissingEntities } from 'root/api/utils/completeMissingEntities';
import { ModifiersClient } from './ModifiersClient';

export const PopulatedMenuClient: HttpClientFactory<PopulatedMenu> = (httpClient) => {
  const MenusApi = MenusClient(httpClient);
  const SectionsApi = SectionsClient(httpClient);
  const ItemsApi = ItemsClient(httpClient);
  const LabelsApi = LabelsClient(httpClient);
  const ModifiersApi = ModifiersClient(httpClient);

  return {
    getAll: async ({ paging, shouldFormatModifiersPrice }) => {
      const [menus, sections, items, labels, modifiers] = await Promise.all([
        MenusApi.getAll({ paging }),
        SectionsApi.getAll({ paging }),
        ItemsApi.getAll({ paging }),
        LabelsApi.getAll({ paging }),
        ModifiersApi.getAll({ paging }),
      ]);

      await Promise.all(
        menus.data.map((menu) =>
          completeMissingEntities(
            menu,
            sections,
            items,
            labels,
            SectionsApi.getAll,
            ItemsApi.getAll,
            LabelsApi.getAll,
            paging?.limit
          )
        )
      );

      return {
        data: menus.data.map((menu) =>
          populateMenu(menu, sections.data, items.data, labels.data, modifiers.data, shouldFormatModifiersPrice)
        ) as PopulatedMenu[],
      };
    },
  };
};
